import React from 'react';
import { MdOutlineGridOn } from 'react-icons/md';
import { BsBoundingBoxCircles, BsPencil } from 'react-icons/bs';
import { PiPolygonFill } from 'react-icons/pi';
import { LiaDrawPolygonSolid } from 'react-icons/lia';
import propTypes from 'prop-types';
import {
  List, ListItem, ListSubheader, Button, Grid, Box, Tooltip, IconButton
} from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';
import Typography from '../../componentes/Typography';
import paleta from '../../configuraciones/paleta';


const ListadoManzanas = ({
  manzanas, manzanaSeleccionada, setManzanaSeleccionada, mostrarModalConfiguracion,
  enManzanas, enLotes, enAsignacion, configuararRegulares, configuararIrregulares, asignacionLotes,
  editarLotesIrregulares
}) => {
  const styles = useStyles();

  const estaConfigurada = (manzana) => {
    if (!enLotes)
      return manzana.manzanaVertices && manzana.manzanaVertices.length >= 3;
  }

  return (
    <List style={{
      border: '1px solid #E1E7ED', borderRadius: 5, width: '100%', padding: 0
    }}>
      <ListSubheader style={{ backgroundColor: paleta.sidebar.active }
      } >
        <Typography style={{ fontWeight: 600 }}>MANZANAS</Typography>
      </ListSubheader >
      {
        manzanas.map((manzana) => {
          const seleccionada = manzana.id === manzanaSeleccionada.id;
          return (
            <ListItem key={manzana.id}
              className={clsx(styles.manzana, { active: seleccionada })}
              button={enLotes}
              onClick={enLotes || enAsignacion ? () => setManzanaSeleccionada(manzana) : null}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <Typography>{manzana.nombre}</Typography>
                </Grid>

                {
                  enManzanas && <Grid item xs={10}>
                    <Button fullWidth size="small" className={styles.btn} onClick={() => setManzanaSeleccionada(manzana)}>
                      {!estaConfigurada(manzana) ? (<>
                        <PiPolygonFill size={18} />
                        <Typography>Dibujar manzana</Typography>
                      </>) : (
                        <>
                          <BsPencil size={18} />
                          <Typography>Editar manzana</Typography>
                        </>
                      )}
                    </Button>
                  </Grid>
                }

                {
                  enLotes && seleccionada && (
                    <Grid item xs={10}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button fullWidth size="small" variant='outlined' className={styles.btn} onClick={() => mostrarModalConfiguracion(true)}>
                            <MdOutlineGridOn size={20} className={clsx(styles.iconoConfigurar)} /> {manzanas.hileres === undefined || manzana.hileras === null ? 'Configurar Lotes' : 'Volver a configurar lotes'}
                          </Button>
                        </Grid>
                        {
                          manzana.hileras && manzana.hileras !== null &&
                          <Grid item xs={12}>
                            {(manzanaSeleccionada.lotes.length - (manzana.irregulares ? manzana.irregulares : 0 )) > 0 &&
                              <Button fullWidth size="small" variant='outlined' className={styles.btn} onClick={() => configuararRegulares(true)}>
                                <BsBoundingBoxCircles size={20} className={clsx(styles.iconoConfigurar)} /> Lotes regulares
                              </Button>}
                          </Grid>
                        }

                        {
                          manzana.hileras !== null &&
                          manzana.irregulares > 0 &&
                          manzana.irregulares !== manzana.lotesVertices?.filter((lv) => lv.regular === false ).length &&
                          <Grid item xs={12}>
                            <Button fullWidth size="small" variant='outlined' className={styles.btn} onClick={() => configuararIrregulares(true)}>
                              <LiaDrawPolygonSolid size={20} className={clsx(styles.iconoConfigurar)} /> Lotes irregulares
                            </Button>
                          </Grid>
                        }
                        {
                          manzana.hileras !== null &&
                          manzana.irregulares > 0 &&
                          manzana.lotesVertices?.some((lv) => lv.regular === false ) &&
                          <Grid item xs={12}>
                            <Button fullWidth size="small" variant='outlined' className={styles.btn} onClick={() => editarLotesIrregulares(true)}>
                              <LiaDrawPolygonSolid size={20} className={clsx(styles.iconoConfigurar)} /> Editar lotes irregulares
                            </Button>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  )
                }

                {
                  enAsignacion && seleccionada && manzana.hileras !== null && (
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Button fullWidth size="small" variant='outlined' className={styles.btn} onClick={() => asignacionLotes(true)}>
                            <MdOutlineGridOn size={20} className={clsx(styles.iconoConfigurar)} /> Asignar Lotes
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }
              </Grid>

            </ListItem>
          )
        })
      }
    </List >
  );
};

ListadoManzanas.propTypes = {
  manzanas: propTypes.array.isRequired,
  manzanaSeleccionada: propTypes.object,
  setManzanaSeleccionada: propTypes.func,
  configurar: propTypes.func,
  mostrarHileras: propTypes.bool,
  enLotes: propTypes.bool,
};

ListadoManzanas.defaultProps = {
  manzanaSeleccionada: {},
  setManzanaSeleccionada: () => { },
  configurar: () => { },
  enLotes: false,
};

export default ListadoManzanas;
