export const ColoresPlanos = {
  Default: 'rgba(69, 144, 250, 0.5)',
  Hilera1: 'rgba(246,73,187, 0.5)',
  Hilera2: 'rgba(146,24,221, 0.5)',
}
export const ColoresSolidosPlanos = {
  Default: 'rgba(69, 144, 250)',
  Hilera1: 'rgba(246,73,187)',
  Hilera2: 'rgba(146,24,221)',
}
export const ColoresEstadoLote = {
  Disponible: 'rgba(10, 200, 175, 0.5)',
  Vendido: 'rgba(64, 154, 142, 0.5)',
  Apartado: 'rgba(222, 185, 114, 0.5)',
  Suspendido: 'rgba(163, 171, 179, 0.5)',
};

export const ColoresSolidoEstadoLote = {
  Disponible: 'rgba(10, 200, 175)',
  Vendido: 'rgba(64, 154, 142)',
  Apartado: 'rgba(222, 185, 114)',
  Suspendido: 'rgba(163, 171, 179)',
};

export const obtenerColorEstadoLote = (estado) => {
  const coloresEstados = {
    1: ColoresEstadoLote.Apartado,
    2: ColoresEstadoLote.Disponible,
    3: ColoresEstadoLote.Suspendido,
    4: ColoresEstadoLote.Vendido,
    Default: ColoresPlanos.Default,
  };
  return coloresEstados[estado] || ColoresPlanos.Default;
};

export const obtenerColorSolidoEstadoLote = (estado) => {
  const coloresEstados = {
    1: ColoresSolidoEstadoLote.Apartado,
    2: ColoresSolidoEstadoLote.Disponible,
    3: ColoresSolidoEstadoLote.Suspendido,
    4: ColoresSolidoEstadoLote.Vendido,
    Default: ColoresPlanos.Default,
  };
  return coloresEstados[estado] || ColoresPlanos.Default;
};

