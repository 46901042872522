const configuraciones = {
  URL_API: 'https://lotes-back-test.gamansolutions.mx/api/v1',
  ELEMENTOS_POR_PAGINA: 10,
  BIGINT_MAXLENGTH: 18,
  INT_MAXLENGTH: 9,
  URL_ARCHIVO_EJEMPLO: 'https://lotes-back-test.gamansolutions.mx/archivos/',
  NOMBRE_ARCHIVO_EJEMPLO: 'EJEMPLO_IMPORTACION_LOTES.xlsx',
};

export default configuraciones;
