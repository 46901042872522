import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
} from '@material-ui/core';
import Plano from '../../componentes/Plano';
import ListadoManzanas from '../../componentes/ListadoManzanas';
import DialogRegulares from './DialogRegulares';
import DialogHileras from './DialogHileras';
import DialogConfiguracionManzana from './DialogConfiguracionManzana';
import { ColoresPlanos, ColoresSolidosPlanos, obtenerColorEstadoLote, obtenerColorSolidoEstadoLote } from '../../constantes/coloresPlanos';
import { esArrayVacio } from '../../utilidades/functions';

export const obtenerPromedio = (coordenadas = []) => {
  const promedio = coordenadas.reduce((a, b, index) => a + b.x, 0) / coordenadas.length;
  return promedio;
};
export const ordenarLotes = (obj1, obj2) => {
  if (obj1.hilera === obj2.hilera) {
    return obtenerPromedio(obj1.coordenadas) - obtenerPromedio(obj2.coordenadas);
  }
  return obj1.hilera - obj2.hilera;
};

export const mapearPoligonos = (manzanas, soloLotes = false, soloManzanas = false) => {
  const poligonos = [
    { fill: ColoresPlanos.Default, coordenadas: [] },
    { coordenadas: [] },
  ];
  const [poligonosManzana, poligonosLotes] = poligonos;

  let indicePoligono = 0;

  for (const manzana of manzanas) {
    if (!soloLotes) {
      poligonosManzana.coordenadas?.push(manzana.manzanaVertices);
    }
    let indiceLote = 0;

    if (!soloManzanas) {
      if (manzana.lotesVertices) {
        const lotesOrdenados = manzana.lotesVertices.sort(ordenarLotes);
        for (const lote of lotesOrdenados) {
          poligonosLotes.coordenadas.push({
            manzanaId: manzana.id,
            ...lote,
            tooltip: `Hilera ${lote.hilera} - Lote ${indiceLote + 1}`,
            indiceLote,
            indicePoligono,
          });
          indicePoligono++;
          indiceLote++;
        }
      } else if (manzana.lote?.some((l) => l.loteVertices && l.loteVertices.length > 0)) {
        const lotesOrdenados = manzana.lotesVertices.sort(ordenarLotes);
        for (const lote of lotesOrdenados) {
          poligonosLotes.coordenadas.push({
            manzanaId: manzana.id,
            ...lote,
            tooltip: `Hilera ${lote.hilera} - Lote ${indiceLote + 1}`,
            indiceLote,
            indicePoligono,
          });
          indicePoligono++;
          indiceLote++;
        }
      }
    }

  }

  return poligonos;
};

export const mapearPoligonosPorManzana = (manzanas) => {
  const poligonosLotesPorManzana = [];
  let indicePoligono = 0;

  for (const manzana of manzanas) {
    const manzanaPoligono = {
      id: manzana.id,
      loteInicial: manzana.loteInicial,
      loteFinal: manzana.loteFinal,
      lotesVertices: manzana.loteVertices || []
    };

    if (manzana.lotesVertices) {
      const lotesOrdenados = manzana.lotesVertices.sort(ordenarLotes);
      let indiceLote = 0;
      for (const lote of lotesOrdenados) {
        manzanaPoligono.lotesVertices.push({
          ...lote,
          indiceLote,
          indicePoligono,
        });
        indicePoligono++;
        indiceLote++;
      }
    }

    poligonosLotesPorManzana.push(manzanaPoligono);
  }

  return poligonosLotesPorManzana;
};

export const mapearPoligonosLotes = (manzanas) => {
  const poligonos = [{ coordenadas: [] }];

  const [poligono] = poligonos;
  for (const manzana of manzanas) {
    for (const lote of manzana.lotes) {
      if (lote.loteVertices.length > 0) {
        poligono.coordenadas.push({
          ...lote,
          coordenadas: lote.loteVertices,
          tooltip: `Lote ${lote.numero}`,
          fill: obtenerColorEstadoLote(lote.estadoId),
          stroke: obtenerColorSolidoEstadoLote(lote.estadoId),
        });

      }
    }
  }
  return poligonos;
};

const Lotes = ({ img, manzanas, setManzanas, puedeContinuar }) => {
  const [manzanaSeleccionada, setManzanaSeleccionada] = useState({});
  const [mostrarDialogConfiguracion, setMostrarDialogConfiguracion] = useState(false);
  const [mostrarDialogo, setMostrarDialogo] = useState(false);
  const [mostrarDialogoHileras, setMostrarDialogoHileras] = useState(false);
  const [capturandoIrregular, setCapturandoIrregular] = useState(false);
  const [editandoIrregular, setEditandoIrregular] = useState(false);
  const puntosIrregulares = useRef(null);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [loteIndice, setLoteIndice] = useState(null);
  const [poligonoIndice, setPoligonoIndice] = useState(null);
  const [editarLoteIrregularSeccionado, setEditarLoteIrregularSeccionado] = useState(false);
  const [verticesAnteriores, setVerticesAnteriores] = useState([]);

  const poligonos = useMemo(() => mapearPoligonos(manzanas), [manzanas]);

  const actualizarManzanas = (prop, value) => {
    const nuevasManzanas = [...manzanas];
    const index = manzanas.findIndex((m) => m.id === manzanaSeleccionada.id);
    if (index !== -1) {
      nuevasManzanas[index][prop] = value;
      setManzanaSeleccionada({
        ...nuevasManzanas[index],
        lotesVertices: nuevasManzanas[index].lotesVertices || [],
      });
      setManzanas(nuevasManzanas);
    }
  }

  const definirLotesPorHilera = (numLotes) => {
    actualizarManzanas('lotesPorHilera', numLotes);
    setModoEdicion(true);
  }

  const cambiarManzanaSeleccionada = (nuevaManzana) => {
    if (nuevaManzana.id === manzanaSeleccionada.id) return;

    setManzanaSeleccionada({
      ...nuevaManzana,
      lotesVertices: nuevaManzana.lotesVertices || [],
    });
  }

  const definirLotesRegulares = (lotes) => actualizarManzanas('lotesVertices', manzanaSeleccionada.lotesVertices.filter((l) => !l.regular).concat(lotes));

  const definirHileraIrregular = (coordenadas) => {
    puntosIrregulares.current = coordenadas;
    if (manzanaSeleccionada.hileras === 1) { agregarIrregular({ value: 1 }) } else { setMostrarDialogoHileras(true); }
    setCapturandoIrregular(false);
  };

  const agregarIrregular = ({ value }) => {
    const hilera = parseInt(value, 10);
    const fill = hilera === 1 ? ColoresPlanos.Hilera1 : ColoresPlanos.Hilera2;
    const stroke = hilera === 1 ? ColoresSolidosPlanos.Hilera1 : ColoresSolidosPlanos.Hilera2;
    actualizarManzanas('lotesVertices',
      [
        ...manzanaSeleccionada.lotesVertices,
        { regular: false, hilera, fill, stroke, coordenadas: puntosIrregulares.current }
      ]);
  };

  const capturarIrregular = () => {
    setModoEdicion(true);
    setCapturandoIrregular(true);
  }

  const iniciarEdicionIrregular = (valor) => {
    setModoEdicion(false);
    setEditandoIrregular(valor);
  }

  const alCancelar = () => {
    setModoEdicion(false);
    setEditandoIrregular(false);
    setLoteIndice(null);
    if (!esArrayVacio(verticesAnteriores)) editarVerticesLoteIrregular(loteIndice, verticesAnteriores);
    setVerticesAnteriores([]);

  }

  const editarVerticesLoteIrregular = (loteIndice, nuevosVertices) => {
    const nuevasManzanas = [...manzanas];
    const index = manzanas.findIndex((m) => m.id === manzanaSeleccionada.id);
    if (index !== -1 && loteIndice !== null) {
      nuevasManzanas[index].lotesVertices[loteIndice].coordenadas = nuevosVertices;
      setManzanas(nuevasManzanas);
      setEditarLoteIrregularSeccionado(false);
    }
  }

  useEffect(() => {
    if (loteIndice !== null) {
      const esLoteIrregular = poligonos[1]?.coordenadas[poligonoIndice]?.regular !== undefined &&
      poligonos[1]?.coordenadas[poligonoIndice]?.regular !== null &&
      poligonos[1]?.coordenadas[poligonoIndice]?.regular === false;
      if (esLoteIrregular) {
        setModoEdicion(true);
        if (esArrayVacio(verticesAnteriores)) {
          setVerticesAnteriores(poligonos[1].coordenadas[poligonoIndice].coordenadas);
          setEditandoIrregular(false);
          editarVerticesLoteIrregular(loteIndice, []);
          setEditarLoteIrregularSeccionado(true);
        }
      }
    }
  }, [loteIndice]);

  useEffect(() => {

    if (manzanas.some((m) => m.lotesVertices && m.lotesVertices.length === m.lotes.length)) {
      puedeContinuar(true);
    } else {
      puedeContinuar(false);
    }
  }, [manzanas]);

  return (
    <>
      <DialogConfiguracionManzana
        mostrar={mostrarDialogConfiguracion}
        alCerrar={() => setMostrarDialogConfiguracion(false)}
        manzanaSeleccionada={manzanaSeleccionada}
        onChange={actualizarManzanas}
        mostrarDialogConfiguracion={setMostrarDialogConfiguracion}
      />
      <DialogHileras
        mostrar={mostrarDialogoHileras}
        alCerrar={() => setMostrarDialogoHileras(false)}
        onChange={agregarIrregular}
        manzanaSeleccionada={manzanaSeleccionada}
      />
      <DialogRegulares
        mostrar={mostrarDialogo}
        alCerrar={() => setMostrarDialogo(false)}
        manzanaSeleccionada={manzanaSeleccionada}
        onChange={definirLotesPorHilera}
        lotesRegulares={manzanaSeleccionada.lotesPorHilera || 0}
      />
      <Box flex={1} display="flex" padding={1} overflow="auto" flexDirection="row">
        <Box flex={1} paddingRight={2}>
          <ListadoManzanas
            manzanas={manzanas.filter((manzana) => manzana.lotes.length > 0 && manzana.manzanaVertices?.length > 0)}
            manzanaSeleccionada={manzanaSeleccionada}
            setManzanaSeleccionada={cambiarManzanaSeleccionada}
            mostrarModalConfiguracion={setMostrarDialogConfiguracion}
            enLotes
            configuararRegulares={setMostrarDialogo}
            configuararIrregulares={capturarIrregular}
            editarLotesIrregulares={setEditandoIrregular}
          />
        </Box>
        <Box flex={4} display="flex" overflow="auto" flexDirection="column">
          <Plano
            img={img}
            poligonos={poligonos}
            numLotes={manzanaSeleccionada.lotesPorHilera}
            onChangeLotes={definirLotesRegulares}
            poligonoValidarPunto={manzanaSeleccionada.manzanaVertices}
            onChange={definirHileraIrregular}
            onChangeIrregular={editarVerticesLoteIrregular}
            opcion="LOTES"
            configurarLotes={Boolean(manzanaSeleccionada.id)}
            manzanaSeleccionada={manzanaSeleccionada}
            grabarPuntos={modoEdicion}
            habilitarEdicion={setModoEdicion}
            generarLotes
            dosHileras={manzanaSeleccionada.hileras === 2}
            capturandoIrregular={capturandoIrregular}
            alCancelar={() => alCancelar()}
            loteIndice={setLoteIndice}
            poligonoIndice={setPoligonoIndice}
            valorLoteIndice={loteIndice}
            editandoIrregulares={editandoIrregular}
            editarLoteIrregularSeccionado={editarLoteIrregularSeccionado}
          />
        </Box>
      </Box>
    </>
  );
}

export default Lotes;
