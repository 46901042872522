import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { toInteger } from 'lodash';
import propTypes from 'prop-types';
import {
  Dialog, Grid, DialogTitle, FormControlLabel,
  FormControl, FormLabel, RadioGroup,
  Radio, DialogContent, DialogActions
} from '@material-ui/core';

import Button from '../../componentes/Button';
import TextField from '../../componentes/TextField'; // Asegúrate de importar el TextField correcto
import { MENSAJE_CAPTURAR_NUMERO_LOTES_IRREGULATES, MENSAJE__LOTES_MAYOR_AL_TOTAL } from '../../constantes/mensajes';

const DialogConfiguracionManzana = ({ mostrar, alCerrar, manzanaSeleccionada, onChange }) => {
  const { getValues, setValue } = useFormContext();
  const [hileras, setHileras] = useState(2);
  const [tieneIrregulares, setTieneIrregulares] = useState(false);

  /**
   * Metodo para asignar los valores de hileras e irregulares a la manzana
   */
  const aceptar = () => {
    const { irregulares } = getValues();

    if (tieneIrregulares) {
      const numIrregulares = toInteger(irregulares);
      if (numIrregulares < 1) {
        toast.warn(MENSAJE_CAPTURAR_NUMERO_LOTES_IRREGULATES);
      } else if (numIrregulares > manzanaSeleccionada?.lotes?.length) {
        toast.warn(MENSAJE__LOTES_MAYOR_AL_TOTAL);
      } else {
        onChange('irregulares', numIrregulares);
        onChange('hileras', hileras);
        limpiarDialog();
      }
    } else {
      onChange('irregulares', 0);
      onChange('hileras', hileras);
      limpiarDialog();
    }
    onChange('lotesVertices', []);
  };

  /**
   * Metodo para limpiar el formulario configuración de lotes
   */
  const limpiarDialog = () => {
    setHileras(2);
    setTieneIrregulares(false);
    setValue('irregulares', null);
    alCerrar();
  }

  const asignarNumeroHileras = ({ target: { value } }) => setHileras(toInteger(value));
  const dialogNumeroIrregulares = ({ target: { value } }) => {
    const valor = value === 'true' ? true : false;
    setTieneIrregulares(valor);
  };

  return (
    <Dialog open={mostrar}>
      <DialogTitle>Configuración de la manzana {manzanaSeleccionada.nombre}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">¿Cuántas hileras tiene la manzana?</FormLabel>
              <RadioGroup aria-label="hilera" name="hilera" onChange={asignarNumeroHileras} value={hileras}>
                <FormControlLabel value={2} control={<Radio />} label="Dos hileras" />
                <FormControlLabel value={1} control={<Radio />} label="Una hilera" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">¿La manzana tiene lotes irregulares?</FormLabel>
              <RadioGroup aria-label="irregular" name="tieneIrregulares" onChange={dialogNumeroIrregulares} value={tieneIrregulares}>
                <FormControlLabel value={true} control={<Radio />} label="Sí" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {tieneIrregulares &&
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">¿Cuántos?</FormLabel>
                <TextField name="irregulares" type='number' />
              </FormControl>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }} >
        <Button
          fullWidth
          label="Cancelar"
          border
          onClick={limpiarDialog}
        />
        <Button
          fullWidth
          label="Aceptar"
          onClick={aceptar}
        />
      </DialogActions>
    </Dialog>
  );
};

DialogConfiguracionManzana.propTypes = {
  /** Flag que indica si el dialogo se muestra o no */
  mostrar: propTypes.bool.isRequired,
  /** Función que se llama al cerrar el dialogo */
  alCerrar: propTypes.func.isRequired,
  /** Numero de manzana seleccionada */
  manzanaSeleccionada: propTypes.object.isRequired,
  /** Función para manejar los cambios en la configuración */
  onChange: propTypes.func.isRequired,
};

export default DialogConfiguracionManzana;
