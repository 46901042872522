
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';
import {
  Dialog, Grid, DialogTitle,
  FormControl, FormLabel,
  DialogContent, DialogActions,
} from '@material-ui/core';
import { toInteger } from 'lodash';

import Button from '../../componentes/Button';
import TextField from '../../componentes/TextField';
import { MENSAJE__LOTES_MAYOR_AL_TOTAL } from '../../constantes/mensajes';


const DialogRegulares = ({
  mostrar, alCerrar, manzanaSeleccionada, onChange, lotesRegulares
}) => {
  const { getValues, setValue } = useFormContext();
  const aceptar = () => {
    const { lotesRegulares } = getValues();
    if ((toInteger(lotesRegulares) * toInteger(manzanaSeleccionada?.hileras)) > manzanaSeleccionada?.lotes?.length) {
      toast.warn(MENSAJE__LOTES_MAYOR_AL_TOTAL);
    } else {
      onChange(lotesRegulares);
      alCerrar();
    }
    
  };

  useEffect(() => {
    setValue('lotesRegulares', lotesRegulares);
  }, [lotesRegulares, setValue]);

  return (
    <Dialog open={mostrar}>
      <DialogTitle>Lotes de la manzana {manzanaSeleccionada.nombre}</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">¿Cuantos lotes contendrá la selección por hilera?</FormLabel>
                  <TextField name="lotesRegulares" />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
      <Button
          fullWidth
          label="Cancelar"
          border
          onClick={() => alCerrar()}
        />
        <Button
          fullWidth
          label="Aceptar"
          onClick={aceptar}
        />
      </DialogActions>
    </Dialog>
  );
};

DialogRegulares.propTypes = {
  /** Flag que indica si el dialogo se muestra o no */
  mostrar: propTypes.bool.isRequired,
  /** Función que se llama al cerrar el dialogo */
  alCerrar: propTypes.func.isRequired,
  /** Numero de manzana seleccionada */
  manzanaSeleccionada: propTypes.object.isRequired
};

export default DialogRegulares;
