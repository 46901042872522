import React, { useEffect, useState, useCallback, } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Stepper, Step, StepLabel, Box, Button, IconButton, StepConnector } from '@material-ui/core';
import { toast } from 'react-toastify';
import { TbMapUp, TbSelectAll, TbTopologyRing } from 'react-icons/tb';
import { MdGridOn } from 'react-icons/md';
import clsx from 'clsx';

import Asignacion from './Asignacion';
import Proyecto from './Proyecto';
import Lotes from './Lotes';
import Manzanas from './Manzanas';
import styles from './styles';
import MapaCompletado from './MapaCompletado';
import Typography from '../../componentes/Typography';
import Modal from '../../componentes/Modal';
import Default from '../../contenedores/Default';
import { ESTADOS_MAPA } from '../../constantes/estadosMapas';
import endpoints from '../../configuraciones/endpoints';
import axiosInstance from '../../configuraciones/axios';
import { ALERTA_CONFIGURAR_LOTES, ALERTA_CONFIGURAR_MANZANA, ALERTA_PROYECTO_SIN_MANZANAS, ALERTA_SUBIR_PLANO, ELEMENTO_NO_ENCONTRADO } from '../../constantes/mensajes';
import { base64ToBlob, esObjetoVacio, urlToBase64 } from '../../utilidades/functions';

const Mapa = ({ history, match }) => {
  const { params: { id } } = match;
  const [pasoActivo, setPasoActivo] = useState(0);
  const [textoNavegacion, setTextoNavegacion] = useState({ textoAnterior: '', textoSiguiente: '' });
  const [tienePlano, setTienePlano] = useState(false);
  const [manzanas, setManzanas] = useState([]);
  const [manzanasOrdenadas, setManzanasOrdenadas] = useState({});
  const [puedeContinuar, setPuedeContinuar] = useState(false);
  const [modalManzanasPendientes, setModalManzanasPendientes] = useState(false);
  const [modalConfiguracionLotesPendientes, setModalConfiguracionLotesPendientes] = useState(false);
  const [plano, setPlano] = useState('');
  const [nombreProyecto, setNombreProyecto] = useState('');
  const classes = styles();

  useEffect(() => {
    if (pasoActivo === 0) {
      if (!plano || plano === 'undefined') {
        setTienePlano(false);
        setPuedeContinuar(false);
      } else {
        setPuedeContinuar(true);
      }
    }
  }, [plano, pasoActivo]);

  const iconoPaso = (icono) => {
    return (
      <IconButton className={clsx(classes.pasoIcono)} disableTouchRipple>
        {icono}
      </IconButton>
    );
  };

  const pasos = [
    { paso: 'Subir plano', icono: iconoPaso(<TbMapUp fontSize="inherit" />) },
    { paso: 'Dibujar Manzanas', icono: iconoPaso(<TbTopologyRing fontSize="inherit" />) },
    { paso: 'Configurar Lotes', icono: iconoPaso(<MdGridOn fontSize="inherit" />) },
    { paso: 'Asignar Lotes', icono: iconoPaso(<TbSelectAll fontSize="inherit" />) },
  ];

  const methods = useForm();

  const estadoMapa = (estado) => {
    const estadosMapeo = {
      [ESTADOS_MAPA.SIN_PLANO]: 0,
      [ESTADOS_MAPA.SIN_MANZANAS]: 1,
      [ESTADOS_MAPA.MANZANAS_PENDIENTES]: 1,
      [ESTADOS_MAPA.LOTES_SIN_CONFIGURAR]: 2,
      [ESTADOS_MAPA.LOTES_PENDIENTES]: 2,
      [ESTADOS_MAPA.LOTES_SIN_ASIGNAR]: 3,
      [ESTADOS_MAPA.COMPLETADO]: 4
    };

    if (estadosMapeo.hasOwnProperty(estado)) {
      setPasoActivo(estadosMapeo[estado]);
    }
  };

  /**
   * Metodo para la validación para continuar a la pantalla siguiente a la actual
   */
  const siguiente = async () => {
    let continuar = true;
    switch (pasoActivo) {
      case 0:

        if (plano === '' || plano === null) {
          toast.warning(ALERTA_SUBIR_PLANO);
          continuar = false;
        } else {
          if (!tienePlano) {
            const formData = new FormData();
            if (plano) formData.append('plano', base64ToBlob(plano));
            await axiosInstance.put(endpoints.proyectoPlano(id), formData, {
              headers: { 'enctype': 'multipart/form-data' }
            }).then(() => { }).catch(() => { continuar = false; });
          }
        }

        if (manzanas.length === 0) {
          continuar = false;
          toast.warning(ALERTA_PROYECTO_SIN_MANZANAS);
        } else {
          await axiosInstance.put(endpoints.estadoMapaProyecto(id, ESTADOS_MAPA.SIN_MANZANAS))
        }

        break;

      case 1:
        const existenManzanasPendientes = manzanas.length === 0 || manzanas.some((m) => !m.manzanaVertices || m.manzanaVertices.length === 0);
        continuar = manzanas.length !== 0 && manzanas.some((m) => m.manzanaVertices || m.manzanaVertices.length !== 0);
        if (!continuar) {
          toast.warning(ALERTA_CONFIGURAR_MANZANA);
        } else {
          if (continuar && existenManzanasPendientes) {
            setModalManzanasPendientes(true);
            continuar = false;
          } else if (continuar && !existenManzanasPendientes) {
            await axiosInstance.put(endpoints.estadoMapaProyecto(id, ESTADOS_MAPA.LOTES_SIN_CONFIGURAR))
          }
        }

        break;

      case 2:
        const existenConfiguracionesPendientes = manzanas.some((m) => m.lotes.length > 0  && (!m.lotesVertices || m.lotes.length !== m.lotesVertices.length));
        continuar = manzanas.some((m) => m.lotesVertices && m.lotesVertices.length !== 0);
        
        if (!continuar) {
          toast.warning(ALERTA_CONFIGURAR_LOTES);
        } else {
          if (continuar && existenConfiguracionesPendientes) {
            setModalConfiguracionLotesPendientes(true);
            continuar = false;
          } else if (continuar && !existenConfiguracionesPendientes) {
            await axiosInstance.put(endpoints.estadoMapaProyecto(id, ESTADOS_MAPA.LOTES_SIN_ASIGNAR))
          }
        }
        break;

      case 3:
        continuar = manzanas.length !== 0 && !manzanas.some((m) => m.lotes.some((l) => !l.loteVertices || l.loteVertices.length === 0));

        if (!continuar) {
          toast.warning('Existen planos por asignar');
        } else {
          if (!esObjetoVacio(manzanasOrdenadas)) {
            setManzanas(manzanasOrdenadas);
            await guardarConfiguracionCompleta(manzanas);
            setManzanasOrdenadas({});
          }
        }
        break;

      default:
        break;
    }

    if (continuar) {
      setPasoActivo((c) => c + 1);
      guardarConfiguracion(manzanas);
    }

  };

  const regresar = useCallback(() => {
    const { location: { state: { pagina } } } = history;
    history.push({
      pathname: '/catalogos/mapas',
      search: pagina && pagina > 1 ? `?pagina=${pagina}` : '',
    });
  }, [history]);

  /**
   * Metodo para la asignacion de vertices de lotes
   */
  const asignarLotesVertices = (manzanasDb) => {
    const nuevasManzanas = [...manzanasDb];
    nuevasManzanas.forEach((manzana) => {
      manzana.lotesVertices = [];
      manzana.lotes.forEach((l) => {
        manzana.lotesVertices.push({ coordenadas: l.loteVertices })
      }
      );
    });
    setManzanas(nuevasManzanas);
  }

  /**
   * Metodo principal para consultar el estado del proyecto
   */
  const consultaEstadoProyecto = useCallback(() => new Promise((resolve, reject) => {
    if (id) {
      const promesas = [
        axiosInstance.get(endpoints.configuracionProyecto(id)),
        axiosInstance.get(endpoints.proyectoPlano(id))
      ];

      Promise.all(promesas).then(async (resultadosPromesas) => {

        const [configuracionDb, planoProyectoDb] = resultadosPromesas;

        estadoMapa(planoProyectoDb?.estadoMapa);

        if (planoProyectoDb?.id) {
          if (planoProyectoDb?.plano !== null) {
            setTienePlano(true);
            setPlano(await urlToBase64(planoProyectoDb.plano));
          }
          if (configuracionDb?.proyecto?.nombre) {
            setNombreProyecto(configuracionDb?.proyecto?.nombre);
          }
          if (configuracionDb?.jsonConfiguracion && configuracionDb.jsonConfiguracion.length > 0) {
            setManzanas(configuracionDb?.jsonConfiguracion);
          } else {
            const manzanasDb = await axiosInstance.get(endpoints.manzanasPlanos(id));
            if (manzanasDb) setManzanas(manzanasDb);
            if (manzanasDb.some((m) => m.lotes.some((l) => l.loteVertices && l.loteVertices.length > 0))) asignarLotesVertices(manzanasDb);
          }

        } else {
          toast.warning(ELEMENTO_NO_ENCONTRADO);
          regresar();
        }

        resolve();

      }).catch(reject);

    } else {
      regresar();
    }

  }), [id, regresar]);

  const guardarConfiguracion = async (configuracion) => {
    await axiosInstance.put(endpoints.configuracionProyecto(id), configuracion);
  };

  const guardarConfiguracionCompleta = async (manzanas) => {
    await axiosInstance.post(endpoints.guardarConfiguracionMapa(id), manzanas);
  };

  useEffect(() => {
    consultaEstadoProyecto();
  }, [consultaEstadoProyecto]);

  useEffect(() => {
    const textosNavegacion = [
      { textoSiguiente: 'Configurar manzanas' },
      { textoAnterior: 'Editar plano', textoSiguiente: 'Configurar lotes' },
      { textoAnterior: 'Editar manzanas', textoSiguiente: 'Asignar lotes' },
      { textoAnterior: 'Editar configuración lotes', textoSiguiente: 'Visualizar mapa' },
      { textoAnterior: 'Editar asignación lotes' },
    ];

    setTextoNavegacion(textosNavegacion[pasoActivo] || {});
  }, [pasoActivo]);

  /**
   * Metodo para aceptar continuar con manzanas pendientes
   */
  const aceptarManzanasPendientes = async () => {
    setPasoActivo((c) => c + 1);
    setModalManzanasPendientes(false);
    await axiosInstance.put(endpoints.estadoMapaProyecto(id, ESTADOS_MAPA.MANZANAS_PENDIENTES));
    guardarConfiguracion(manzanas);
  }

  /**
   * Metodo para aceptar continuar con lotes pendientes
   */
  const aceptarLotesPendientes = async () => {
    setPasoActivo((c) => c + 1);
    setModalConfiguracionLotesPendientes(false);
    await axiosInstance.put(endpoints.estadoMapaProyecto(id, ESTADOS_MAPA.LOTES_PENDIENTES));
    guardarConfiguracion(manzanas);
  }

  return <Default
    titulo={nombreProyecto}
    placeHolder={''}
    soloCancelar={pasoActivo === 4}
    guardar={() => guardarConfiguracion(manzanas)}
    cancelar={regresar}
    labelGuardar={'Guardar cambios'}
    labelCancelar={'Regresar al listado'}
    frmId="frmPlano"
  >
    <FormProvider {...methods}>
      <form id="frmPlano" style={{ display: 'flex', flex: 1, padding: 0, overflow: 'auto', flexDirection: 'column' }}>
        <Box flex={1} display="flex" padding={0} overflow="auto" flexDirection="column">
          {
            pasoActivo !== 4 &&
            <Box>
              <Stepper activeStep={pasoActivo} alternativeLabel connector={<StepConnector classes={{ line: classes.conectorPaso }} />}>
                {pasos.map(({ icono, paso }) => (
                  <Step key={paso}>
                    <StepLabel icon={icono} classes={{ label: classes.pasoLabel }}>{paso}</StepLabel >
                  </Step>
                ))}
              </Stepper>
            </Box>
          }

          <Box flex={1} display="flex" padding={1} overflow="auto" flexDirection="column">
            {pasoActivo === 0 && <Proyecto id={id} setPlano={setPlano} plano={plano} />}
            {pasoActivo === 1 && <Manzanas id={id} img={plano} regresar={setPasoActivo} puedeContinuar={setPuedeContinuar} manzanas={manzanas} setManzanas={setManzanas} />}
            {pasoActivo === 2 && <Lotes proyectoId={id} img={plano} manzanas={manzanas} setManzanas={setManzanas} puedeContinuar={setPuedeContinuar} />}
            {pasoActivo === 3 && <Asignacion id={id} manzanas={manzanas} setManzanas={setManzanas} setManzanasOrdenadas={setManzanasOrdenadas} puedeContinuar={setPuedeContinuar} img={plano} />}
            {pasoActivo === 4 && <MapaCompletado id={id} manzanas={manzanas} setManzanas={setManzanas} img={plano} />}
          </Box>
          <Box>
            <Grid container spacing={2} justifyContent='center'>
              {
                pasoActivo !== 0 &&
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setPasoActivo((c) => c - 1)}
                    className={clsx(classes.btn, classes.regresar)}
                  >
                    {textoNavegacion.textoAnterior}
                  </Button>
                </Grid>
              }

              {
                pasoActivo !== 4 &&
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={siguiente}
                    className={clsx(classes.btn, classes.continuar)}
                    disabled={!puedeContinuar}
                  >
                    {textoNavegacion.textoSiguiente}
                  </Button>
                </Grid>
              }
            </Grid>
          </Box>
          <Modal
            titulo='Advertencia'
            open={modalManzanasPendientes}
            showActions
            actions={
              <>
                <Button
                  onClick={() => aceptarManzanasPendientes()}
                  variant="contained"
                >
                  Aceptar
                </Button>
                <Button onClick={() => setModalManzanasPendientes(false)} variant="outlined" >
                  Cancelar
                </Button>
              </>
            }>
            <Typography>Aún hay manzanas pendientes por capturar, ¿desea continuar?</Typography>
          </Modal>
          <Modal
            titulo='Advertencia'
            open={modalConfiguracionLotesPendientes}
            showActions
            actions={
              <>
                <Button
                  onClick={() => aceptarLotesPendientes()}
                  variant="contained"
                >
                  Aceptar
                </Button>
                <Button onClick={() => setModalConfiguracionLotesPendientes(false)} variant="outlined" >
                  Cancelar
                </Button>
              </>
            }>
            <Typography>Aún hay lotes pendientes por configurar, ¿desea continuar?</Typography>
          </Modal>
        </Box>
      </form>
    </FormProvider>
  </Default>;
};

export default React.memo(Mapa);
