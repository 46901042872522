import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';

import Plano from '../../componentes/Plano';
import ListadoManzanas from '../../componentes/ListadoManzanas';
import { esObjetoVacio } from '../../utilidades/functions';
import { mapearPoligonos } from './Lotes';

const Manzanas = ({ id, img, regresar, puedeContinuar, manzanas, setManzanas }) => {
  const [manzanaSeleccionada, setManzanaSeleccionada] = useState({});
  const [verticesAnteriores, setVerticesAnteriores] = useState({});
  const [modoEdicion, setModoEdicion] = useState(false)
  const poligonos = useMemo(() => mapearPoligonos(manzanas, false, true), [manzanas]);

  /** Funcion que actualiza una propiedad de una manzana */
  const actualizarManzanaEnListado = (prop, valor) => {
    const edicionManzanas = [...manzanas];
    const index = manzanas.findIndex(({ id }) => manzanaSeleccionada.id === id);
    edicionManzanas[index][prop] = valor;
    setManzanas(edicionManzanas);
  }

  /** Define los vertices de una manzana */
  const definirManzanaVertices = (manzanaVertices) => {
    actualizarManzanaEnListado('manzanaVertices',
      manzanaVertices.map((m) => ({ ...m, manzanaId: manzanaSeleccionada.id })));
    if (!esObjetoVacio(verticesAnteriores) || verticesAnteriores === manzanaVertices) {
      setVerticesAnteriores({});
    }
    setManzanaSeleccionada({});
  }

  const editarVerticesManzana = () => {
    setModoEdicion(true);
    setVerticesAnteriores(manzanaSeleccionada.manzanaVertices)
    actualizarManzanaEnListado('manzanaVertices', []);
  }

  const cancelar = () => {
    if (!esObjetoVacio(verticesAnteriores)) { definirManzanaVertices(verticesAnteriores) };
    setManzanaSeleccionada({});
  }

  useEffect(() => {
    if (manzanas.length !== 0 && manzanas.some((m) => m.manzanaVertices && m.manzanaVertices.length !== 0)) {
      puedeContinuar(true);
    } else {
      puedeContinuar(false);
    }
  }, [manzanas]);

  /** Se alterna visualización de botón "editar plano" */
  useEffect(() => {
    if(!esObjetoVacio(manzanaSeleccionada)){
      editarVerticesManzana();
    }
  }, [manzanaSeleccionada]);

  return (
    <>
      <Box flex={1} display="flex" padding={1} overflow="auto" flexDirection="row">
        <Box flex={1} paddingRight={2}>
          <ListadoManzanas
            manzanas={manzanas}
            enManzanas
            manzanaSeleccionada={manzanaSeleccionada}
            setManzanaSeleccionada={setManzanaSeleccionada}
          />
        </Box>
        <Box flex={4} display="flex" overflow="auto" flexDirection="column">
          <Plano
            img={img}
            esManzana
            grabarPuntos={modoEdicion}
            onChange={definirManzanaVertices}
            poligonos={poligonos}
            alCancelar={cancelar}
            manzanaSeleccionada={manzanaSeleccionada}
            regresar={regresar}
            editarVertices={editarVerticesManzana}
            habilitarEdicion={setModoEdicion}
          />
        </Box>
      </Box>
    </>
  );
};

export default Manzanas;